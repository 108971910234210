$btn-color: #c2b56f;
$btn-text-color: #081939;
$steper-bg: #848c9c;
$steper-border: #081939;
$border-color: #c2b56f;

.twitter-bs-wizard {
  .twitter-bs-wizard-nav {
    position: relative;

    &:before {
      content: '';
      width: 100%;
      height: 2px;
      background-color: $gray-300;
      position: absolute;
      left: 0;
      top: 26px;
    }

    .step-number {
      display: inline-block;
      width: 38px;
      height: 38px;
      line-height: 34px;
      border: 2px solid $gray-300;
      color: $white;
      text-align: center;
      border-radius: 50%;
      position: relative;
      background-color: $steper-bg;

      @media (max-width: 991.98px) {
        display: block;
        margin: 0 auto 8px !important;
      }
    }

    .nav-link {
      .step-title {
        display: block;
        margin-top: 8px;
        font-weight: $font-weight-bold;
        color: $btn-text-color;

        @media (max-width: 575.98px) {
          display: none;
        }
      }

      &.active {
        background-color: transparent;
        color: $gray-700;

        .step-number {
          border: 2px solid $steper-border;
          background-color: $btn-text-color;
          color: $white;
        }
      }
    }
  }

  .twitter-bs-wizard-pager-link {
    padding-top: 24px;
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
    justify-content: end;

    li {
      display: inline-block;

      a {
        display: inline-block;
        padding: 6px 40px;
        background-color: $btn-color;
        color: $btn-text-color;
        border-radius: 0.25rem;
        font-weight: bold;
      }

      &.disabled {
        a {
          cursor: not-allowed;
          background-color: lighten($btn-color, 8%);
        }
      }

      &.next {
        float: right;
      }
    }
  }
}

.twitter-bs-wizard-tab-content {
  padding-top: 24px;
  min-height: 262px;
}

.twitter-bs-wizard-border-radio {
  border: 1px solid $border-color !important;
}
