@import '../../../assets/scss/theme.scss';

$random:#B5BAC4;

.apexcharts-canvas {
  text {
    fill: $text-muted !important;
  
  }
}

