//
// print.scss
//

// Used invoice page
@media print {
  .vertical-menu,
  .right-bar,
  .page-title-box,
  .navbar-header,
  .footer {
    display: none !important;
  }
  .card-body,
  .main-content,
  .right-bar,
  .page-content,
  body {
    padding: 0;
    margin: 0;
  }

  .card {
    border: 0;
  }
}

input[type='radio'] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  outline: none;
  border: 3px solid gray;
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

input[type='radio']:checked:before {
  background: #C2B56F;
}

input[type='radio']:checked {
  border-color: #C2B56F;
}


